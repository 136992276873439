import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import parseISO from 'date-fns/parseISO';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import {
  DateTimeRange,
  DiningOptionBehavior,
  FulfillmentType,
  LeadTimeRule,
  PickupWindowRule,
  PreorderRule,
  Selection,
  TimeBasedRules
} from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from 'public/components/online_ordering/CartContext';
import { FutureFulfillmentDates } from 'public/components/online_ordering/FulfillmentContext';
import { WrappedModifier } from 'public/components/online_ordering/types';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';

import {
  getPickupWindowConstraints,
  isInPickupWindow,
  validateItemWithPickupWindowFulfillmentTime
} from './pickupWindowUtils';

export type TimeBasedRuleContextType = {
  timeBasedRulesMap: { [itemGuid: string]: TimeBasedRules },
  selectionsInCartWithTBRs: Selection[],
  validateItemFulfillmentTime: (itemGuid?: string | null) => boolean,
  getItemLeadTime: (itemGuid?: string | null) => number | undefined,
  getFilteredFutureSchedules: (earliestFulfillmentTime: Date, futureSchedules: FutureFulfillmentDates, behavior?: DiningOptionBehavior | null) => FutureFulfillmentDates,
  getEarliestFulfillmentTime: (itemGuid?: string | null, diningBehavior?: DiningOptionBehavior | null) => Date,
  itemInCartWithMaxLeadTime: ItemWithTimeBasedRule | undefined,
  itemsInCartWithPreorderRule: ItemWithTimeBasedRule[],
  itemsInCartWithPickupWindowRule: ItemWithTimeBasedRule[],
  verifyNoConflictingItemsInCart: (item: ItemWithTimeBasedRule) => boolean,
  verifyItemIsFulfillable: (itemGuid: string | null, fulfillmentType: FulfillmentType, futureSchedules: FutureFulfillmentDates) => boolean,
  itemToAdd: ItemToAdd | undefined,
  setItemToAdd: (item: ItemToAdd | undefined) => void,
  setConflictingItemInCart: (item: ItemWithTimeBasedRule | undefined) => void,
  conflictingItemInCart: ItemWithTimeBasedRule | undefined,
  loading: boolean
}

export const TimeBasedRuleContext = createContext<TimeBasedRuleContextType | undefined>(undefined);

export type ItemWithTimeBasedRule = {
  itemGuid: string,
  leadTimeRule?: LeadTimeRule,
  preorderRule?: PreorderRule,
  pickupWindowRule?: PickupWindowRule,
  selectionGuid?: string | null,
  name?: string | null
}

type ItemToAdd = ItemWithTimeBasedRule & {
  modifier: WrappedModifier,
  quantity: number
}

/*
Some menu items have time-based rules governing when they can be ordered, e.g. only 24 hours in advance.
This context maintains a map of item guids to their time-based rules, and exposes helper methods for
consumers to ensure we are enforcing those rules on any order that includes an item with a time-based rule.
 */
export const TimeBasedRuleContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { menus } = useOOMenus({ });
  const { cart } = useCart();
  const { ooRestaurant } = useRestaurant();
  const { ooTimeBasedPreorderRuleEnabled, dotFoodWasteSecondarySales } = useFlags();
  const [itemToAdd, setItemToAdd] = useState<ItemToAdd | undefined>();
  const [conflictingItemInCart, setConflictingItemInCart] = useState<ItemWithTimeBasedRule | undefined>();
  const [loading, setLoading] = useState(true);
  const menuRef = useRef(menus);
  // We don't want to keep recalculating the timeBasedRulesMap every time the useOOMenus hook runs,
  // so keep a stable version of the menus object in a ref.
  useEffect(() => {
    if(!isEqual(menuRef.current, menus)) {
      menuRef.current = menus;
    }
    setLoading(menus === undefined || ooTimeBasedPreorderRuleEnabled === undefined);
  }, [menus, ooTimeBasedPreorderRuleEnabled]);

  // A flat map of itemGuids to time based rules for any item across all menus with time based rules defined on it.
  const timeBasedRulesMap = useMemo(() => {
    const tbrMap: { [itemGuid: string]: TimeBasedRules } = {};
    if(!loading) {
      menuRef.current?.map(menu => menu.groups?.map(group => group?.items?.map(item => {
        if(item && item.guid && item.timeBasedRules) {
          const rules = { ...item?.timeBasedRules };
          if(!ooTimeBasedPreorderRuleEnabled) {
            delete rules.preorderRule;
          }
          if(!dotFoodWasteSecondarySales) {
            delete rules.pickupWindowRule;
          }
          if(rules.preorderRule || rules.leadTimeRule || rules.pickupWindowRule) {
            tbrMap[item.guid] = rules;
          }
        }
      })));
    }
    return tbrMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, dotFoodWasteSecondarySales]);

  const selectionsInCartWithTBRs: Selection[] = useMemo(() => {
    const selections = [];
    for(const selection of cart?.order?.selections ?? []) {
      if(selection?.itemGuid && timeBasedRulesMap[selection.itemGuid]) {
        selections.push(selection);
      }
    }
    return selections as Selection[];
  }, [cart?.order?.selections, timeBasedRulesMap]);

  const itemsInCartWithPreorderRule: ItemWithTimeBasedRule[] = useMemo(() => {
    const itemsWithPreorderRules = [];
    for(const selection of selectionsInCartWithTBRs) {
      if(selection.itemGuid) {
        const preorderRule = timeBasedRulesMap[selection.itemGuid]?.preorderRule;
        if(preorderRule) {
          itemsWithPreorderRules.push({
            itemGuid: selection.itemGuid,
            selectionGuid: selection.guid,
            preorderRule,
            name: selection.name
          });
        }
      }
    }
    return itemsWithPreorderRules;
  }, [selectionsInCartWithTBRs, timeBasedRulesMap]);

  const itemsInCartWithPickupWindowRule: ItemWithTimeBasedRule[] = useMemo(() => {
    const itemsWithPickupWindowRules = [];
    for(const selection of selectionsInCartWithTBRs) {
      if(selection.itemGuid) {
        const pickupWindowRule = timeBasedRulesMap[selection.itemGuid]?.pickupWindowRule;
        if(pickupWindowRule) {
          itemsWithPickupWindowRules.push({
            itemGuid: selection.itemGuid,
            selectionGuid: selection.guid,
            pickupWindowRule,
            name: selection.name
          });
        }
      }
    }
    return itemsWithPickupWindowRules;
  }, [selectionsInCartWithTBRs, timeBasedRulesMap]);

  const itemInCartWithMaxLeadTime = useMemo(() => {
    const minimumLeadTimes: ItemWithTimeBasedRule[] = [];
    for(const selection of selectionsInCartWithTBRs) {
      if(selection.itemGuid) {
        const leadTime = timeBasedRulesMap[selection.itemGuid]?.leadTimeRule?.leadTime;
        if(leadTime) {
          minimumLeadTimes.push({
            itemGuid: selection.itemGuid,
            selectionGuid: selection.guid,
            leadTimeRule: { leadTime },
            name: selection.name
          });
        }
      }
    }

    // It's possible there will be two items with the same minimum lead time in the cart,
    // but `itemInCartWithMaxLeadTime` is only referenced in two cases:
    // 1. A new item with an even longer lead time has just been added to the cart, so we
    //    can be sure it's the only one with that longest lead time.
    // 2. We're checking whether there's *any* item in the cart with a minimum lead time
    //    for the sake of determining whether to display "ASAP" as a fulfillment option,
    //    in which case it doesn't matter which item we return if there's more than one.
    // Note: We currently assume all leadTimes are in hours, though this may change in the future.
    return maxBy(minimumLeadTimes, 'leadTimeRule.leadTime');
  }, [selectionsInCartWithTBRs, timeBasedRulesMap]);


  const getItemLeadTime = useCallback((itemGuid?: string | null) => {
    if(itemGuid) {
      return timeBasedRulesMap[itemGuid]?.leadTimeRule?.leadTime ?? undefined;
    }
    return undefined;
  }, [timeBasedRulesMap]);

  const getEarliestFulfillmentTime = useCallback((itemGuid?: string | null, diningBehavior?: DiningOptionBehavior): Date => {
    // The earliest fulfillment time for an item is now + the quote time for the current cart, plus any lead time defined on the item
    const now = new Date(new Date(Date()).setSeconds(0));
    const diningOptionBehavior = diningBehavior ?? cart?.diningOptionBehavior;
    const quoteTimeInMinutes = (
      diningOptionBehavior === DiningOptionBehavior.TakeOut
        ? cart?.takeoutQuoteTime :
        diningOptionBehavior === DiningOptionBehavior.Delivery ? cart?.deliveryQuoteTime : undefined
    ) ?? 0;
    const withQuoteTime = new Date(now.setMinutes(now.getMinutes() + quoteTimeInMinutes));
    // If the item is already in the cart, the cart's quote time takes the lead time into account.  If not, add it on.
    if(!selectionsInCartWithTBRs.find(s => s.itemGuid === itemGuid)) {
      const leadTimeInHours = (itemGuid ? timeBasedRulesMap[itemGuid]?.leadTimeRule?.leadTime : undefined) ?? 0;
      return new Date(withQuoteTime.setHours(withQuoteTime.getHours() + leadTimeInHours));
    }
    return withQuoteTime;
  }, [timeBasedRulesMap, cart, selectionsInCartWithTBRs]);

  const validateItemFulfillmentTime = useCallback((itemGuid?: string | null) => {
    const _validateItemWithMLTFulfillmentTime = (leadTime: number, fulfillmentTime?: string | null) => {
      if(!leadTime) {
        return true;
      }
      // Indicates an ASAP order, which, now that we know there's a lead time present, shouldn't be valid
      if(!fulfillmentTime) {
        return false;
      }
      const parsedFulfillmentTime = parseISO(fulfillmentTime);
      const now = new Date();
      const minPrepTimeInMinutes = (cart?.diningOptionBehavior === DiningOptionBehavior.TakeOut ? ooRestaurant?.minimumTakeoutTime : ooRestaurant?.minimumDeliveryTime) ?? 0;
      const totalPrepTimeInMinutes = leadTime * 60 + minPrepTimeInMinutes;
      const earliestFulfillmentTime = new Date(now.setMinutes(now.getMinutes() + totalPrepTimeInMinutes));
      return parsedFulfillmentTime > earliestFulfillmentTime;
    };

    const _validateItemWithPreorderFulfillmentTime = (preorder: PreorderRule, fulfillmentTime?: string | null) => {
      const { start, end } = preorder.fulfillmentDateRange as DateTimeRange;

      // A null fulfillment time indicates an ASAP order, so we'll just set it to 'now'.  (Since preorder deals with
      // day-level granularity, we won't bother with the minute-level specificity of the actual ASAP quote time).
      const time = fulfillmentTime ? parseISO(fulfillmentTime) : new Date();
      const fulfillmentStart = parseISO(start);

      // The front end of the TBR admin spa currently enforces an end time on all ranges, so this should never actually
      // be undefined, but in case it somehow is, we'll treat it as an open-ended range
      if(!end) {
        return fulfillmentStart < time;
      }

      // Otherwise, fulfillment time is valid as long as it falls within the preorder rule's fulfillment range
      const fulfillmentEnd = parseISO(end);
      return fulfillmentStart < time && time < fulfillmentEnd;
    };

    const timeBasedRules = itemGuid && timeBasedRulesMap[itemGuid];
    if(!timeBasedRules) {
      // If there is no time-based rule for this item, the fulfillment time is necessarily valid
      return true;
    }
    if(timeBasedRules.leadTimeRule) {
      return _validateItemWithMLTFulfillmentTime(timeBasedRules.leadTimeRule.leadTime, cart?.fulfillmentDateTime);
    } else if(timeBasedRules.preorderRule) {
      return _validateItemWithPreorderFulfillmentTime(timeBasedRules.preorderRule, cart?.fulfillmentDateTime);
    } else if(timeBasedRules.pickupWindowRule) {
      return validateItemWithPickupWindowFulfillmentTime(
        timeBasedRules.pickupWindowRule,
        cart?.fulfillmentDateTime || null,
        cart?.diningOptionBehavior || DiningOptionBehavior.TakeOut,
        ooRestaurant?.schedule?.upcomingSchedules || [],
        ooRestaurant?.timeZoneId || 'America/New_York'
      );
    } else {
      return true;
    }
  }, [timeBasedRulesMap, cart, ooRestaurant]);

  const verifyNoConflictingItemsInCart = useCallback((item: ItemToAdd): boolean => {
    if(item.preorderRule) {
      const _rangesOverlap = (range1: DateTimeRange, range2: DateTimeRange) => {
        const { start: start1, end: end1 } = range1;
        const { start: start2, end: end2 } = range2;
        return !(end1 && parseISO(start2) >= parseISO(end1) || end2 && parseISO(end2) <= parseISO(start1));
      };

      const { start, end } = item.preorderRule.fulfillmentDateRange as DateTimeRange;
      const conflictingPreorderItem = itemsInCartWithPreorderRule.find(cartItem =>
        !_rangesOverlap({ start, end }, cartItem.preorderRule?.fulfillmentDateRange as DateTimeRange));
      // If it conflicts with existing preorder items, it cannot be added
      if(conflictingPreorderItem) {
        setConflictingItemInCart(conflictingPreorderItem);
        setItemToAdd(item);
        return false;
      }
      // If the cart contains an item with a lead time that goes beyond the last fulfillable date of this item's preorder range, it cannot be added
      if(itemInCartWithMaxLeadTime && end && getEarliestFulfillmentTime(itemInCartWithMaxLeadTime.itemGuid) > parseISO(end)) {
        setConflictingItemInCart(itemInCartWithMaxLeadTime);
        setItemToAdd(item);
        return false;
      }

      // If item has a preorder rule, do not allow it to be added with an item that has a pickup window rule
      if(itemsInCartWithPickupWindowRule.length > 0) {
        setConflictingItemInCart(itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1]);
        setItemToAdd(item);
        return false;
      }
    } else if(item.leadTimeRule) {
      // If the item's lead time goes beyond the latest fulfillable date of a preorder item in the cart, it cannot be added
      const now = new Date(Date());
      const earliestFulfillmentTime = new Date(now.setHours(now.getHours() + item.leadTimeRule.leadTime));
      const conflictingPreorderItem = itemsInCartWithPreorderRule.find(cartItem => {
        const { end } = cartItem.preorderRule?.fulfillmentDateRange as DateTimeRange;
        return end && earliestFulfillmentTime > parseISO(end);
      });
      if(conflictingPreorderItem) {
        setConflictingItemInCart(conflictingPreorderItem);
        setItemToAdd(item);
        return false;
      }

      // If item has a lead time rule, do not allow it to be added with an item that has a pickup window rule
      if(itemsInCartWithPickupWindowRule.length > 0) {
        setConflictingItemInCart(itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1]);
        setItemToAdd(item);
        return false;
      }
    }

    // If item has a pick up window rule, do not allow it to be added with an item that has a lead time rule
    if(item.pickupWindowRule) {
      if(itemInCartWithMaxLeadTime) {
        setConflictingItemInCart(itemInCartWithMaxLeadTime);
        setItemToAdd(item);
        return false;
      }

      if(itemsInCartWithPreorderRule.length > 0) {
        setConflictingItemInCart(itemsInCartWithPreorderRule[itemsInCartWithPreorderRule.length - 1]);
        setItemToAdd(item);
        return false;
      }
    }

    setConflictingItemInCart(undefined);
    setItemToAdd(undefined);
    return true;
  }, [itemsInCartWithPreorderRule, itemInCartWithMaxLeadTime, itemsInCartWithPickupWindowRule, getEarliestFulfillmentTime]);

  const verifyItemIsFulfillable = (itemGuid: string | null, fulfillmentType: FulfillmentType, futureSchedules: FutureFulfillmentDates): boolean => {
    const timeBasedRules = itemGuid ? timeBasedRulesMap[itemGuid] : undefined;
    if(!timeBasedRules) {
      return true;
    }

    const earliestFulfillmentTime = getEarliestFulfillmentTime(itemGuid, cart?.diningOptionBehavior);
    if(timeBasedRules.preorderRule) {
      // When the Rx does not allow scheduled orders, all that really matters is that the current quote time falls within the item's preorder rule's fulfillment range
      if(fulfillmentType === FulfillmentType.Asap && futureSchedules.length === 0) {
        const { start, end } = timeBasedRules.preorderRule.fulfillmentDateRange as DateTimeRange;
        return parseISO(start) < earliestFulfillmentTime && (Boolean(end) && earliestFulfillmentTime < parseISO(end as string));
      }
      // Otherwise, it's a matter of whether there's any overlap between the item's fulfillable range, and the restaurant's scheduled fulfillment times
      return filterFutureSchedulesForPreorder(futureSchedules, [timeBasedRules.preorderRule]).length > 0;
    } else if(timeBasedRules.leadTimeRule) {
      return filterFutureSchedulesForLeadTime(earliestFulfillmentTime, futureSchedules, timeBasedRules.leadTimeRule).length > 0;
    } else if(timeBasedRules.pickupWindowRule) {
      // When the Rx does not allow scheduled orders, check if current quote time falls within the item's pickup window
      if(fulfillmentType === FulfillmentType.Asap && futureSchedules.length === 0) {
        const pickupWindowConstraints = getPickupWindowConstraints(
          timeBasedRules.pickupWindowRule,
          cart?.diningOptionBehavior || DiningOptionBehavior.TakeOut,
          ooRestaurant?.schedule?.upcomingSchedules || [],
          ooRestaurant?.timeZoneId || 'America/New_York'
        );
        return pickupWindowConstraints ? isInPickupWindow(pickupWindowConstraints, earliestFulfillmentTime) : false;
      }
      return filterFutureSchedulesForPickupWindow(futureSchedules, timeBasedRules.pickupWindowRule, cart?.diningOptionBehavior).length > 0;
    }
    return true;
  };

  const _getFulfillableRange = (preorderRules: PreorderRule[]): DateTimeRange => {
    const allStarts = preorderRules.map(rule => rule.fulfillmentDateRange?.start);
    const allEnds = preorderRules.map(rule => rule.fulfillmentDateRange?.end);
    const latestStart = maxBy(allStarts, start => start && parseISO(start) )!;
    const earliestEnd = minBy(allEnds, end => end && parseISO(end) );
    return { start: latestStart, end: earliestEnd };
  };

  const getFilteredFutureSchedules = (earliestFulfillmentTime: Date, futureSchedules: FutureFulfillmentDates, behavior?: DiningOptionBehavior | null): FutureFulfillmentDates => {
    if(futureSchedules.length === 0) {
      return futureSchedules;
    }

    let filteredSchedules: FutureFulfillmentDates = futureSchedules;
    const preorderRules = itemsInCartWithPreorderRule.map(item => item.preorderRule as PreorderRule);
    const pickupWindowRules = itemsInCartWithPickupWindowRule.map(item => item.pickupWindowRule as PickupWindowRule);

    // Successively filter for each potential TBR in the cart, since it is possible to have multiple compatible rules
    // (e.g. an item with a MLT and a preorder item that can both be picked up in a few days)
    if(preorderRules && preorderRules.length > 0) {
      filteredSchedules = filterFutureSchedulesForPreorder(filteredSchedules, preorderRules);
    }
    if(pickupWindowRules && pickupWindowRules[0]) {
      filteredSchedules = filterFutureSchedulesForPickupWindow(filteredSchedules, pickupWindowRules[0], behavior);
    }
    if(itemInCartWithMaxLeadTime?.leadTimeRule) {
      filteredSchedules = filterFutureSchedulesForLeadTime(earliestFulfillmentTime, filteredSchedules, itemInCartWithMaxLeadTime?.leadTimeRule);
    }
    return filteredSchedules;
  };

  const _isSameDay = (day1: Date, day2: Date) => {
    return day1.getFullYear() === day2.getFullYear() && day1.getMonth() === day2.getMonth() && day1.getDate() === day2.getDate();
  };

  const filterFutureSchedulesForPreorder = (futureSchedules: FutureFulfillmentDates, preorderRules: PreorderRule[]): FutureFulfillmentDates => {
    if(futureSchedules.length === 0) {
      return futureSchedules;
    }
    const { start, end } = _getFulfillableRange(preorderRules);
    const parsedStart = parseISO(start);

    // we've already ascertained futureSchedules is non-empty
    const firstDateInList = parseISO(futureSchedules[0]!.date);
    const lastDateInList = parseISO(futureSchedules[futureSchedules.length - 1]!.date);

    // If the fulfillment start time is later than all future schedule dates, or fulfillment end time is earlier than
    // all future schedule dates, there are no valid dates.
    // The front end of the TBR admin spa currently enforces an end time on all ranges, so this should never actually
    // be undefined, but in case it somehow is, we'll treat it as an open-ended range
    if(parsedStart > lastDateInList || end && parseISO(end) < firstDateInList) {
      return [];
    }

    let startIndex = futureSchedules.findIndex(date => _isSameDay(parseISO(date.date), parsedStart));
    let endIndex = end ? futureSchedules.findIndex(date => _isSameDay(parseISO(date.date), parseISO(end))) : futureSchedules.length;
    startIndex = startIndex === -1 ? 0 : startIndex;
    endIndex = endIndex === -1 ? futureSchedules.length - 1 : endIndex;
    return futureSchedules.slice(startIndex, endIndex + 1);
  };

  const filterFutureSchedulesForLeadTime = (earliestFulfillmentTime: Date, futureSchedules: FutureFulfillmentDates, leadTimeRule: LeadTimeRule): FutureFulfillmentDates => {
    if(futureSchedules.length === 0) {
      return futureSchedules;
    }
    const leadTime = leadTimeRule?.leadTime;
    if(!leadTime) {
      return futureSchedules;
    }

    // Find the date in futureSchedules that earliestFulfillmentTime falls during.  All dates before this one should not be included.
    // All dates after should be included in their entirety.  This particular date's times will need further filtering.
    const pivotIndex = futureSchedules.findIndex(date => {
      return _isSameDay(parseISO(date.date), earliestFulfillmentTime);
    });

    if(pivotIndex === -1) {
      // This could mean either that all the dates are valid or none of them are.
      return parseISO(futureSchedules[futureSchedules.length - 1]!.date) < earliestFulfillmentTime ? [] : futureSchedules;
    }
    const pivotDate = futureSchedules[pivotIndex]!;
    const filteredTimes = pivotDate?.times.filter(time => parseISO(time.time) >= earliestFulfillmentTime);
    // The earliest fulfillment time for the date came after the latest available time, so exclude it from the filtered results
    if(filteredTimes.length === 0) {
      return futureSchedules.slice(pivotIndex + 1);
    }
    return [{ ...pivotDate, times: filteredTimes }, ...futureSchedules.slice(pivotIndex + 1)];
  };

  const filterFutureSchedulesForPickupWindow = (
    futureSchedules: FutureFulfillmentDates,
    rule: PickupWindowRule,
    behavior?: DiningOptionBehavior | null
  ): FutureFulfillmentDates => {
    if(futureSchedules.length === 0) {
      return futureSchedules;
    }
    const pickupWindowConstraints = getPickupWindowConstraints(
      rule,
      behavior || DiningOptionBehavior.TakeOut,
      ooRestaurant?.schedule?.upcomingSchedules || [],
      ooRestaurant?.timeZoneId || 'America/New_York'
    );
    if(!pickupWindowConstraints) {
      return [];
    }
    for(const schedule of futureSchedules) {
      const firstAvailableTimeIndex = schedule.times.findIndex(time => {
        return isInPickupWindow(pickupWindowConstraints, parseISO(time.time));
      });
      if(firstAvailableTimeIndex !== -1) {
        return [{
          ...schedule,
          times: schedule.times.slice(firstAvailableTimeIndex)
        }];
      }
    }
    return [];
  };

  return (
    <TimeBasedRuleContext.Provider value={{
      timeBasedRulesMap,
      selectionsInCartWithTBRs,
      validateItemFulfillmentTime,
      getItemLeadTime,
      getFilteredFutureSchedules,
      getEarliestFulfillmentTime,
      itemInCartWithMaxLeadTime,
      itemsInCartWithPreorderRule,
      itemsInCartWithPickupWindowRule,
      verifyNoConflictingItemsInCart,
      verifyItemIsFulfillable,
      itemToAdd,
      setItemToAdd,
      setConflictingItemInCart,
      conflictingItemInCart,
      loading
    }}>
      {props.children}
    </TimeBasedRuleContext.Provider>
  );
};

export const useTimeBasedRules = () => {
  const context = useContext(TimeBasedRuleContext);
  if(!context) {
    throw new Error('useTimeBasedRules must be used within a TimeBasedRuleContextProvider');
  }

  return context;
};

export const useOptionalTimeBasedRules = () => useContext(TimeBasedRuleContext);
